import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Form from '@nubank/nuds-web/components/Form/Form';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Icon from '@nubank/nuds-web/components/Icon/Icon';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';
import Image from '@nubank/www-latam-commons/components/Image/Image';

import { sentryException, ERROR_SEVERITY } from '../../../../../utils/sentry';
import { useSiteContext } from '../../../../../components/SiteContext/SiteContext';
import { useRegistrationFormContext } from '../../RegistrationForm/RegistrationForm';
import { isValidPersonRFC } from '../../../../../utils/form/validationUtils';
import Spinner from '../../../../../components/Spinner/Spinner';
import { curpAutofillEvent, personalInfoRegisterEvent } from '../../../tracking';
import { sendCandidateDocuments } from '../../../../../domains/candidate/candidateDocuments';

import states from './statesConversion';
import DisplayedInformation from './styles';
import {
  CancelButton, StyledButton, StyledTextFieldBox, LoaderBox, FeedbackBox,
  Counter, ErrorMessage,
} from './ConfirmCURPInformationStyles';
import TaxIdDrawer from './TaxIdDrawer';

// Exeriment ID Variants
const AUTOFILL_EXPERIMENT_ID = '341525';
const AUTOFILL_BUTTON_EXPERIMENT_VARIANT = '42899';

const StyledTitle = styled(Typography)`
  margin-bottom: 12px;
  
  span {
    color: #820AD1;
  }
`;

const StyledSubtitle = styled(Typography)`
  padding-right: 10px;

  span {
    color: #820AD1;
    font-weight: bold;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: 8px;
  padding-top: 5px;
`;

const ConfirmCURPInformation = () => {
  const { discoveryUrlsList } = useSiteContext();
  const { curpValidationResponse } = useRegistrationFormContext();
  const activeAutofillVariant = useFigPiiExperiment(AUTOFILL_EXPERIMENT_ID);
  const [showTaxIdDrawer, setShowTaxIdDrawer] = useState(false);
  const [displayTaxId, setDisplayTaxId] = useState(curpValidationResponse.tax_id);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidMessageRFC, setInvalidMessageRFC] = useState(false);

  const strExtract = (str, start, end) => str.substring(start, end).toUpperCase();
  const getNumber = (n, m) => strExtract(curpValidationResponse.birthdate, n, m);

  const entity = curpValidationResponse.state_of_birth;
  const filterState = () => states.filter(s => s.entity === entity)[0].value;
  const getGender = curpValidationResponse.gender.toUpperCase() === 'MALE' ? 'H' : 'M';
  const xpVariant = activeAutofillVariant === AUTOFILL_BUTTON_EXPERIMENT_VARIANT ? 'btn' : 'drw';

  useEffect(() => {
    if (strExtract(displayTaxId, 0, 10) !== strExtract(curpValidationResponse.citizen_id, 0, 10)) {
      setInvalidMessageRFC(true);
    } else {
      setInvalidMessageRFC(false);
    }
  }, [displayTaxId]);

  useEffect(() => {
    curpAutofillEvent(`${xpVariant} confirmation displayed`);
  }, []);

  const maskData = str => str.substring(0, 2).toUpperCase() + str.substring(2, str.length).replace(/./g, '•');

  const [returnToPreviousStep, setReturnToPreviousStep] = useState(false);

  const rfcTriggerEvents = () => {
  // Identify RFC changes
    if (strExtract(displayTaxId, 0, 4)
    !== strExtract(curpValidationResponse.citizen_id, 0, 4)) {
      curpAutofillEvent(`${xpVariant} updated name`);
    }

    if (strExtract(displayTaxId, 4, 10)
    !== strExtract(curpValidationResponse.citizen_id, 4, 10)) {
      curpAutofillEvent(`${xpVariant} updated birthdate`);
    }

    if (strExtract(displayTaxId, 10, 13)
    !== strExtract(curpValidationResponse.citizen_id, 10, 13)) {
      curpAutofillEvent(`${xpVariant} updated homoclave`);
    }
  };

  const errorHandler = error => sentryException({
    error,
    flow: 'application_flow',
    checkpoint: 'sendCandidateDocuments',
    namespace: '<PersonalInfoStep />',
    level: ERROR_SEVERITY.CRITICAL,
  });

  const handleSubmit = async ({
    nextStep, setSubmitting, prevStep,
  }) => {
    if (returnToPreviousStep) {
      curpAutofillEvent(`${xpVariant} no soy yo`);
      prevStep();
    } else {
      personalInfoRegisterEvent();
      curpAutofillEvent(`${xpVariant} si soy yo`);
      setIsLoading(true);

      // Event trigger
      if (curpValidationResponse.tax_id !== displayTaxId) {
        curpAutofillEvent(`${xpVariant} rfc updated`);
        rfcTriggerEvents();
      }

      try {
        await sendCandidateDocuments(curpValidationResponse.citizen_id,
          displayTaxId, discoveryUrlsList);
      } catch (error) {
        errorHandler(error);
      }

      setSubmitting(false);
      nextStep();
    }
  };

  return (
    <Form.Step
      initialValues={{
        taxId: displayTaxId,
        names: curpValidationResponse.names,
        firstSurname: curpValidationResponse.first_surname,
        secondSurname: curpValidationResponse.second_surname,
        gender: getGender,
        day: getNumber(8, 10),
        month: getNumber(5, 7),
        year: getNumber(0, 4),
        countryOfBirth: curpValidationResponse.nationality,
        entity: filterState(),
      }}
      onSubmit={handleSubmit}
      initialTouched={{ taxId: true }}
      enableReinitialize
    >
      {({
        isDisabled,
        isSubmitting,
      }) => (
        <Box
          paddingTop={{ xs: '24px', lg: '40px' }}
          paddingBottom="4x"
          paddingHorizontal={{ xs: '24px', lg: '40px' }}
        >
          <StyledTitle variant="heading3">
            <span> Revisa </span>
            {' '}
            tu CURP y RFC
          </StyledTitle>
          <StyledSubtitle variant="subtitle1">
            Valida que tus
            <span> datos sean correctos, </span>
            {' '}
            para que podamos validar tu identidad y evitar errores en tu solicitud.
          </StyledSubtitle>

          <DisplayedInformation
            labelName="CURP"
            text={curpValidationResponse.citizen_id}
            purple
          />

          <StyledTextFieldBox
            activeFeedback={invalidMessageRFC}
            color="#ff8e16"
          >
            { isLoading ? (
              <LoaderBox>
                <Spinner />
              </LoaderBox>
            )
              : (
                <LoaderBox>
                  <Image
                    alt="Icono de un candado"
                    src="personal-information/pencil.svg"
                    webp={false}
                  />
                </LoaderBox>
              )}

            <TextField
              id="taxId"
              name="taxId"
              autoComplete="off"
              maxLength={13}
              label="RFC"
              syncValidations={{
                required: '',
                taxId: '',
              }}
              value={displayTaxId}
              onInput={e => setDisplayTaxId(e.target.value.toUpperCase())}
            />
          </StyledTextFieldBox>
          <FeedbackBox adjustMargin>
            <ErrorMessage>
              {invalidMessageRFC && isValidPersonRFC(displayTaxId) ? 'Sugerimos que tu RFC coincida con tu CURP' : null}
            </ErrorMessage>
            <Counter input={displayTaxId.length} length={13}>
              {`${13 - displayTaxId.length}/13`}
            </Counter>
          </FeedbackBox>

          <Box
            marginTop="-2x"
            onClick={() => setShowTaxIdDrawer(true)}
          >
            <Typography
              variant="subtitle1"
              color="primary.default"
              strong
            >
              No sé mi RFC
              <StyledIcon name="arrow-right" />
            </Typography>
          </Box>

          <DisplayedInformation
            labelName="Primer nombre"
            text={maskData(curpValidationResponse.names)}
          />

          <DisplayedInformation
            labelName="Primer Apellido"
            text={maskData(curpValidationResponse.first_surname)}
          />

          { curpValidationResponse.second_surname
          && (
          <DisplayedInformation
            labelName="Segundo Apellido"
            text={maskData(curpValidationResponse.second_surname)}
          />
          )}

          <Typography
            variant="caption"
            color="black.defaultT70"
            strong
            marginTop={{ xs: '4x' }}
          >
            RENAPO protege tu información y solo nos comparte
            los datos necesarios para tu solicitud.
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            marginTop="12x"
            marginBottom="6x"
          >
            <CancelButton
              type="submit"
              extended
              variant="contained"
              styleVariant="primary"
              intlKey="PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_AUTOFILL_XP_PREVIOUS_BTN_LABEL"
              iconProps={{ name: 'chevron-right', title: 'Continuar' }}
              onClick={() => setReturnToPreviousStep(true)}
            />
            <StyledButton
              type="submit"
              extended
              variant="contained"
              styleVariant="primary"
              disabled={isDisabled || isSubmitting}
              intlKey="PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_AUTOFILL_XP_NEXT_BTN_LABEL"
              iconProps={{ name: 'check', title: 'Continuar' }}
              onClick={() => setReturnToPreviousStep(false)}
            />

            <TaxIdDrawer
              close={() => setShowTaxIdDrawer(false)}
              isOpen={showTaxIdDrawer}
            />
          </Box>

        </Box>
      )}
    </Form.Step>

  );
};

export default ConfirmCURPInformation;
