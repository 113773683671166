import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

import { REGISTRATION_EVENTS_CATEGORY } from '../../utils/gtm';

// GA4 Goal 1
export const emailOnHeroRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'start registration form',
    eventLabel: 'CTA on hero - button click',
    form: {
      type: 'CTA',
      id: 'email-on-hero',
      category: 'email',
    },
  });
};

export const headerCTARegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'start registration form from header',
    eventLabel: 'CTA on header - button click',
    form: {
      type: 'CTA',
      id: 'start-from-cta-on-header-version',
      category: 'header',
    },
  });
};

// GA4 Goal 2 - phone
export const phoneStepRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'phone',
    eventLabel: 'phone number step',
    form: {
      type: 'phone input',
      id: 'phone-step',
      category: 'phone',
    },
  });
};

// GA4 Goal 3 NOT USED
export const emailStepRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'email',
    eventLabel: 'email step',
    form: {
      type: 'email input',
      id: 'email-step',
      category: 'email',
    },
  });
};

// GA4 Goal 4 - mailConfirmation
export const confirmEmailStepRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'email input confirmation',
    eventLabel: 'email confirmation',
    form: {
      type: 'email confirmation input',
      id: 'confirm-email-step',
      category: 'email',
    },
  });
};

// GA4 Goal 5 - personalInformation
export const personalInfoRegisterEvent = dob => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'personal information',
    eventLabel: 'send personal information',
    form: {
      type: 'personal information inputs',
      id: 'personalinfo-step',
      category: 'personal info',
      field: [{
        dimension1: dob,
      }],
    },
  });
};

// GA4 Goal 6 - address
export const addressStepRegisterEvent = (locality = '', postCode = '', city = '', addressState = '') => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'address',
    eventLabel: 'send address',
    form: {
      type: 'address inputs',
      id: 'address-step',
      category: 'address',
      field: [{
        dimension1: locality,
        dimension2: postCode,
        dimension3: city,
        dimension4: addressState,
      }],
    },
  });
};

// GA4 Goal 7 - nip
export const nipStepRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'nip',
    eventLabel: 'nip confirm',
  });
};

export const resendOTPRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    id: 'otp-enviar-de-nuevo',
    eventAction: 'OTP resend click',
    eventLabel: 'OTP resend',
  });
};

// GA4 Goal 9 - RTR - Approved
// Cuenta + SeC, Cuenta + CC
export const approvedScreenRegisterViewEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'registration form confirm',
    eventLabel: 'approved screen',
    form: {
      type: 'approved scrren view',
      id: 'approved-screen',
      category: 'approved screen',
    },
  });
};

// GA4 Goal 9 - RTR - Denied
// Neutral Screen / Timeout
export const deniedScreenRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'registration form confirm',
    eventLabel: 'neutral screen',
    form: {
      type: 'neutral screen view',
      id: 'denied-screen',
      category: 'neutral screen',
    },
  });
};

// RTR - Only Cuenta
export const cuentaRTREvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'cuenta approved screen',
    eventLabel: 'cuenta approved screen',
  });
};

// RTR - Secured Card + Cuenta
export const securedCardRTREvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'secured card approved screen',
    eventLabel: 'secured card approved screen',
  });
};

// RTR - Credit Card + Cuenta
export const creditCardRTREvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'credit approved screen',
    eventLabel: 'credit approved screen',
  });
};

// GA4 Click Android or iOS
export const downloadAppBtnLP = (operatingSystem, page) => {
  sendEvent('APP_EVENTS', {
    eventCategory: 'APP',
    eventAction: 'clicked btn app landing page',
    eventLabel: 'download',
    operatingSystem,
    page,
  });
};

// GA4 Snippet User Id
export const userIdGA4 = userId => {
  sendEvent('USER_ID_EVENTS', {
    eventCategory: 'USER_ID',
    eventAction: 'sent user id',
    eventLabel: 'user id',
    userId,
  });
};

// GA4 Unknow Zip Code
export const unknownZipCodeRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'unknown zip code',
    eventLabel: 'unknown zip code - button click',
  });
};

// GA4 Goal 10? - contact data submit
export const contactDataStepRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'contact data step submit',
    eventLabel: 'contact data',
  });
};

// GA4 Goal 11? - buro validation OTP submit
export const buroValidationOTPRegisterEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'buro validation OTP submit',
    eventLabel: 'buro validation OTP',
  });
};

// GA4 - Calculator amount change
export const calculatorAmountChange = calculatorAmount => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'calculator amount change',
    eventLabel: 'amount change',
    calculatorAmount,
  });
};

// GA4 - Calculator time change
export const calculatorTimeChange = calculatorTime => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'calculator time change',
    eventLabel: 'time change',
    calculatorTime,
  });
};

// GA4 - Calculator time change
export const calculatorOnScreen = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'calculator on screen',
    eventLabel: 'calculator on screen',
  });
};

// GA4 - Calculator time change
export const calculatorApplicationSent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'calculator application sent',
    eventLabel: 'calculator application sent',
  });
};

// GA4 - Personal Information CURP Feedback XP
export const curpTimeoutEvent = curpValidationTime => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'curp timeout',
    eventLabel: 'curp timeout',
    curpValidationTime,
  });
};

export const curpValidEvent = curpValidationTime => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'curp valid',
    eventLabel: 'curp valid',
    curpValidationTime,
  });
};

export const curpInvalidEvent = curpValidationTime => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'curp invalid',
    eventLabel: 'curp invalid',
    curpValidationTime,
  });
};

export const curpValidationTimeEvent = curpValidationTime => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'curp validation time',
    eventLabel: 'curp validation time',
    curpValidationTime,
  });
};

export const rfcInvalidEvent = () => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: 'rfc invalid',
    eventLabel: 'rfc invalid',
  });
};

// GA4 - Personal Information CURP Feedback XP
export const curpFeedbackEvent = event => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: `pi feedback ${event}`,
    eventLabel: `pi feedback ${event}`,
  });
};

// GA4 - Personal Information CURP Feedback XP
export const curpAutofillEvent = event => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: `pi autofill ${event}`,
    eventLabel: `pi autofill ${event}`,
  });
};

// GA4 - Personal Information CURP Feedback XP
export const personalInformationEvent = event => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: `personal information ${event}`,
    eventLabel: `personal information ${event}`,
  });
};

// GA4 - Personal Information CURP Feedback XP
export const displayedFunnelScreensEvent = event => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: `${event} displayed step`,
    eventLabel: `${event} displayed step`,
  });
};

// GA4 - RTR Phase 5 Experiment
export const rtrPhase5ExperimentEvent = event => {
  sendEvent('REGISTRATION_EVENTS', {
    eventCategory: REGISTRATION_EVENTS_CATEGORY,
    eventAction: `rtr xp ${event}`,
    eventLabel: `rtr xp ${event}`,
  });
};

