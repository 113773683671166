import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';

import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { cuentaRTREvent, rtrPhase5ExperimentEvent } from '../../../../tracking';
import { FormStepContainer } from '../../../../styles/FormStepContainer';
import useMobileOS from '../../../../../../utils/hooks/useMobileOS';
import { StyledButton } from '../../styles/StyledButton';
import { StyledHeader } from '../../styles/Header';

const StyledTitle = styled(Typography)`
  font-size: 28px;

  @media (width >= 768px) {
    font-size: 2.1rem;
  }
`;

function CuentaOnlyVariantB({ email }) {
  const { closeRegistrationForm } = useSiteContext();
  const mobileSystem = useMobileOS();

  const getOSLink = () => {
    if (mobileSystem === 'ios') {
      window.open('https://link.nubank.com.br/5YMt1FwBMBb', '_blank');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.nu.production&listing=ric', '_blank');
    }
  };

  useEffect(() => {
    cuentaRTREvent();
    rtrPhase5ExperimentEvent('cuenta b');
  }, []);

  return (
    <FormStepContainer height="100%">
      <StyledHeader>
        <Button
          variant="basic"
          onClick={() => closeRegistrationForm(true)}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      {/* TITLE AND SUBTITLE */}
      <StyledTitle
        variant="heading2"
      >
        ¡Listo! Ya tenemos una oferta hecha para ti 💜
      </StyledTitle>

      <Typography
        variant="subtitle1"
        marginTop="8x"
        marginBottom="8x"
        whiteSpace="pre-line"
      >
        <Typography strong tag="span" variant="subtitle1" color="primary">
          {'Continúa para conocer la oferta '}
        </Typography>
        {' y contratar Nu, ¡todo sin hacer filas en una sucursal! \n\n'}
        <Typography strong tag="span" variant="subtitle1" color="primary">
          {'¡Descarga la app para continuar! '}
        </Typography>
        {'Da clic en '}
        <Typography strong tag="span" variant="subtitle1">
          {'“Continuar o empezar con tu registro” '}
        </Typography>
        {'y usa el correo '}
        <Typography strong tag="span" variant="subtitle1" color="primary">
          {email}
        </Typography>
        {' y '}
        <Typography strong tag="span" variant="subtitle1">
          {'el mismo número celular. '}
        </Typography>
      </Typography>

      <Image
        src="one-step/rtr/green-check.svg"
        alt="Ilustracion de un icono verde con una palomita"
        width="150px"
        webp={false}
      />

      {/* DOWNLOAD BUTTON */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="6x"
        marginTop="12x"
      >
        <StyledButton
          iconProps={{ name: 'arrow-right' }}
          extended
          type="button"
          onClick={() => getOSLink()}
        >
          Descargar App Nu
        </StyledButton>
      </Box>

    </FormStepContainer>
  );
}

CuentaOnlyVariantB.propTypes = {
  email: PropTypes.string,
};

CuentaOnlyVariantB.defaultProps = {
  email: 'tu email',
};

export default CuentaOnlyVariantB;
