import React, { useEffect } from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import Link from '@nubank/nuds-web/components/Link/Link';
import PropTypes from 'prop-types';

import useMobileOS from '../../../../../../utils/hooks/useMobileOS';
import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { useDynamicYieldValues } from '../../../../../../utils/dynamicYield';
import { approvedScreenRegisterViewEvent, rtrPhase5ExperimentEvent, securedCardRTREvent } from '../../../../tracking';
import { FormStepContainer } from '../../../../styles/FormStepContainer';
import { StyledHeader } from '../../styles/Header';
import { StyledButton } from '../../styles/StyledButton';
import { StyledTitle } from '../../styles/StyledTitle';

function SecuredCardVariantA({ email }) {
  const { closeRegistrationForm } = useSiteContext();
  const mobileSystem = useMobileOS();
  const { values: dynamicYieldValues } = useDynamicYieldValues();

  useEffect(() => {
    approvedScreenRegisterViewEvent();
    securedCardRTREvent();
    rtrPhase5ExperimentEvent('secured a');
  }, []);

  const getOSLink = () => {
    if (mobileSystem === 'ios') {
      window.open('https://link.nubank.com.br/5YMt1FwBMBb', '_blank');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.nu.production&listing=ric', '_blank');
    }
  };

  return (
    <FormStepContainer height="100%">
      <StyledHeader>
        <Button
          variant="basic"
          onClick={() => closeRegistrationForm(true)}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      {/* TITLE AND SUBTITLE */}

      <StyledTitle
        variant="heading2"
        marginBottom="6x"
      >
        👉
        {' '}
        <Typography
          variant="heading2"
          tag="span"
          color="primary"
          strong
        >
          {' '}
          Descarga la app Nu
        </Typography>
        {' '}
        para el siguiente paso
      </StyledTitle>

      <Typography
        variant="subtitle1"
        marginBottom="6x"
        whiteSpace="pre-line"
      >
        <Typography strong tag="span" style={{ color: '#820AD1' }}>¿Qué sigue?</Typography>
        {' \n'}
        <Box paddingLeft="2x">
          <Typography
            variant="subtitle1"
            marginBottom="2x"
          >
            1. Descarga la app.
          </Typography>
          <Typography
            variant="subtitle1"
            marginBottom="2x"
          >
            2. Elige
            {' '}
            <Typography tag="span" strong variant="subtitle1">
              “Continuar o empezar registro”.
            </Typography>
          </Typography>
          <Typography
            variant="subtitle1"
            marginBottom="2x"
          >
            3.
            {' '}
            <Typography tag="span" variant="subtitle1">
              Entra con el correo
            </Typography>
            <Typography tag="span" strong variant="subtitle1" color="primary">
              {' '}
              {email}
              {' '}
            </Typography>
            <Typography tag="span" strong variant="subtitle1">
              y el número celular
            </Typography>
            {' '}
            que ya registraste.
          </Typography>
        </Box>
      </Typography>

      {/* APPSTORE BUTTONS */}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="6x"
      >
        <StyledButton
          iconProps={{ name: 'arrow-right' }}
          extended
          type="button"
          onClick={() => getOSLink()}
        >
          Descargar App Nu
        </StyledButton>
      </Box>

      <Typography
        strong
        variant="subtitle1"
        marginBottom="4x"
        whiteSpace="pre-line"
      >
        {'Conoce los productos que podrás \n contratar:'}
      </Typography>

      {/* CARDS ROWS */}

      <Box
        marginBottom={{
          xs: '1x',
          md: '1x',
          lg: '3.25rem',
        }}
        paddingHorizontal={{
          xs: '0',
        }}
      >

        <Box
          marginBottom={{
            xs: '1x',
            md: '1x',
            lg: '3.25rem',
          }}
          paddingHorizontal={{
            xs: '0',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            marginBottom="6x"
          >
            {/* CREDIT CARD + CUENTA ROW */}
            <Box
              paddingVertical="4x"
              display="flex"
              paddingHorizontal="2x"
              justifyContent="flex-start"
              borderRadius="12px"
              background="#F3EDFF"
              marginBottom="4x"
            >
              <Image
                alt="Icono de una tarjeta"
                src="one-step/rtr/secured-pre-ric.svg"
                webp={false}
                width="120px"
              />
              <Box
                paddingRight="4x"
                width="100%"
              >
                <Typography
                  variant="paragraph1"
                  whiteSpace="pre-line"
                  marginLeft="2x"
                  marginBottom="2x"
                  strong
                >
                  Tarjeta Garantizada y Cuenta Nu
                </Typography>

                <Typography
                  variant="paragraph1"
                  whiteSpace="pre-line"
                  marginLeft="2x"
                >
                  para que tu historial crediticio tenga una nueva oportunidad
                </Typography>
              </Box>
            </Box>

            <Box marginBottom="4x">
              <Link
                href="https://cdn.nubank.com.br/MX/folleto-informativo.pdf"
                variant="action"
                typographyProps={{ variant: 'paragraph1' }}
                target="_blank"
              >
                CAT de Tarjeta de Crédito Nu
              </Link>
            </Box>

            {/* CUENTA ONLY ROW */}
            <Box
              paddingVertical="4x"
              display="flex"
              paddingHorizontal="2x"
              justifyContent="flex-start"
              borderRadius="12px"
              background="#F3EDFF"
              marginBottom="4x"
            >
              <Image
                alt="Icono de una tarjeta"
                src="one-step/rtr/debit-pre-ric.svg"
                webp={false}
                width="120px"
              />
              <Box
                paddingRight="4x"
                width="100%"
              >
                <Typography
                  variant="paragraph1"
                  whiteSpace="pre-line"
                  marginLeft="2x"
                  marginBottom="2x"
                  strong
                >
                  Cuenta Nu:
                  <Typography
                    variant="paragraph1"
                    marginLeft="2x"
                    whiteSpace="pre-line"
                    tag="span"
                  >
                    {`Débito y en la app puedes crecer tus \n ahorros ${dynamicYieldValues.dynamicYield}% al año.`}
                  </Typography>
                </Typography>

              </Box>
            </Box>

            <Box marginBottom="4x">
              <Link
                href="/gat"
                variant="action"
                typographyProps={{ variant: 'paragraph1' }}
                target="_blank"
              >
                GAT Nominal y Real de Cuenta Nu
              </Link>
            </Box>
          </Box>
        </Box>

      </Box>

    </FormStepContainer>
  );
}

SecuredCardVariantA.propTypes = {
  email: PropTypes.string,
};

SecuredCardVariantA.defaultProps = {
  email: 'tu email',
};

export default SecuredCardVariantA;
