export const useDynamicYieldValues = () => {
  const dynamicState = {
    values: {
      dynamicYield: '13.50',
      dynamicGATNominal: '14.45',
      dynamicGATReal: '10.27',
      // 7 DAYS
      dynamicYield7Days: '13.60',
      dynamicGATNominal7Days: '14.75',
      dynamicGATReal7Days: '10.38',
      // 28 DAYS
      dynamicYield28Days: '13.90',
      dynamicGATNominal28Days: '14.91',
      dynamicGATReal28Days: '10.71',
      // 90 days
      dynamicYield90Days: '14.75',
      dynamicGATNominal90Days: '15.89',
      dynamicGATReal90Days: '11.66',
    },
    textTemplates: {
      VALUES_CALCULATED_AT: '9 de agosto de 2024',
      MINIMUM_SAVINGS_AMOUNT: '$0.01',
      FROZEN_MINIMUM_SAVINGS_AMOUNT: '$50.00',
      VALID_UNTIL: '9 de octubre de 2024',
    },
  };

  return dynamicState;
};

export const FROZEN_TIME = {
  A_90_DAYS: 90,
  A_28_DAYS: 28,
  A_7_DAYS: 7,
  NO_FROZEN: 0,
};
