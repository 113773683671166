import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Image from '@nubank/nuds-web/components/Image/Image';

const DisplayedInformation = styled(Box)`
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;

  p {
    font-size: 12px;
    font-weight: bold;
    color: #adadad;
  }

  div {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #EFEFEF;
  }

  div > p {
    font-size: 18px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${props => (props.purple === true ? '#820AD1' : '#adadad')};
  }
`;

const DisplayedInformationBox = ({ labelName, text, purple }) => (
  <DisplayedInformation purple={purple}>
    <Typography>
      {labelName}
    </Typography>
    <Box>
      <Typography>
        {text}
      </Typography>
      <Image
        alt="Icono de un candado"
        src={purple ? 'one-step/lock.svg' : 'personal-information/lock.svg'}
        webp={false}
      />

    </Box>
  </DisplayedInformation>
);

DisplayedInformationBox.defaultProps = {
  purple: false,
};

DisplayedInformationBox.propTypes = {
  labelName: PropTypes.string.isRequired,
  purple: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default DisplayedInformationBox;
