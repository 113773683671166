import React, { useState, useEffect } from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';

import { getPolling, startRealtimeAnalysis } from '@nubank/www-latam-commons/services/prospect';
import { sendRegisterProspectEvent } from '@nubank/www-latam-commons/services/analytics';

import { useRegistrationFormContext } from '../RegistrationForm/RegistrationForm';
import { useSiteContext } from '../../../../components/SiteContext/SiteContext';

import DeniedScreen from './DeniedScreen';
import LoadingScreen from './LoadingScreen';
import CuentaOnlyVariantB from './content-experiment/phaseFive/CuentaOnlyVariantB';
import SecuredCardVariantA from './content-experiment/phaseFive/SecuredCardVariantA';
import MultiproductVariantA from './content-experiment/phaseFive/MultiproductVariantA';

const SCREENS = {
  APPROVED: 'APPROVED',
  NEUTRAL: 'NEUTRAL',
};

const DecisionStep = () => {
  const { email } = useSiteContext();
  const { prospectRegistrationData } = useRegistrationFormContext();
  const [analisisResult, setAnalisisResult] = useState(false);
  const [analyzingPercentage, setAnalyzingPercentage] = useState(10);
  const [currentScreen, setCurrentScreen] = useState();

  const updateRealtimeAnalysis = percentage => {
    setAnalyzingPercentage(percentage);
  };

  const handleProspectRegistration = async () => {
    const {
      hasThrottle,
      marketingId,
      hasRealtimeAnalysis,
      realtimeUrl,
      prospectType,
    } = prospectRegistrationData;
    try {
      if (hasThrottle) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      sendRegisterProspectEvent(prospectType, marketingId);

      if (!hasRealtimeAnalysis) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      const polling = await getPolling(realtimeUrl);

      if (!polling) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      const analisis = await startRealtimeAnalysis({
        url: realtimeUrl,
        onProgress: updateRealtimeAnalysis,
        polling,
      });

      updateRealtimeAnalysis(100);

      if (!analisis || !analisis.template) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      setAnalisisResult(analisis.template);

      if (analisis.template.id === 'no_products') {
        setTimeout(() => setCurrentScreen(SCREENS.NEUTRAL), 1200);
      } else {
        setTimeout(() => setCurrentScreen(SCREENS.APPROVED), 1200);
      }
    } catch {
      setCurrentScreen(SCREENS.NEUTRAL);
    }
  };

  return (
    <Form.Step isForm={false}>
      {({ formsValues }) => {
        useEffect(() => {
          handleProspectRegistration();
        }, []);

        if (currentScreen === 'APPROVED') {
        /// ////////////////////////// CUENTA ONLY////////////////////////////////////////
          if (analisisResult.id === 'savings_account') {
            return <CuentaOnlyVariantB email={email} />;
          }

          /// ////////////////////////// SECURED CARD////////////////////////////////////////
          if (analisisResult.id === 'secured_credit_card_and_nuconta') {
            return <SecuredCardVariantA email={email} />;
          }

          // ///////////////////////////// MULTIPRODUCT ////////////////////////////////////////
          return <MultiproductVariantA email={email} />;
        }

        /// ////////////////////////// DENIED/NEUTRAL ////////////////////////////////////////
        if (currentScreen === 'NEUTRAL') {
          return (
            <DeniedScreen name={formsValues.names} email={email} />
          );
        }

        /// ////////////////////////// LOADING ////////////////////////////////////////
        return (
          <LoadingScreen percentage={analyzingPercentage} />
        );
      }}
    </Form.Step>
  );
};

export default DecisionStep;
