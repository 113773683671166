import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';

import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { FormStepContainer } from '../../../../styles/FormStepContainer';
import {
  approvedScreenRegisterViewEvent, creditCardRTREvent, downloadAppBtnLP, rtrPhase5ExperimentEvent,
} from '../../../../tracking';
import AppStoreBadge from '../../../../../../components/AppStoreBadge/AppStoreBadge';
import { useDynamicYieldValues } from '../../../../../../utils/dynamicYield';
import { StyledHeader } from '../../styles/Header';

const StyledTitle = styled(Typography)`
  font-size: 24px;

  @media (width >= 768px) {
    font-size: 2.1rem;
  }
`;

const StyledAppButtonsContainer = styled(Box)`
  gap: 20px;
`;

function MultiproductVariantA({ email }) {
  const { closeRegistrationForm } = useSiteContext();
  const { values: dynamicYieldValues } = useDynamicYieldValues();

  useEffect(() => {
    approvedScreenRegisterViewEvent();
    creditCardRTREvent();
    rtrPhase5ExperimentEvent('multiproduct a');
  }, []);

  return (
    <FormStepContainer height="100%">
      <StyledHeader>
        <Button
          variant="basic"
          onClick={() => closeRegistrationForm(true)}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      {/* TITLE AND SUBTITLE */}
      <StyledTitle
        variant="heading2"
        marginBottom="4x"
      >
        Tu tarjeta de crédito y Cuenta Nu ya están listas para que las contrates 🥳️
      </StyledTitle>

      <Typography
        variant="subtitle1"
        marginBottom="4x"
        strong
      >
        Todo está listo para que continúes en la app.
        <Typography
          strong
          variant="subtitle1"
          tag="span"
          color="primary"
        >
          {' '}
          Sigue estos pasos:
        </Typography>
      </Typography>

      <Box paddingLeft="2x">
        <Typography
          variant="subtitle1"
          marginBottom="2x"
        >
          1. Descarga la app.
        </Typography>
        <Typography
          variant="subtitle1"
          marginBottom="2x"
        >
          2. Elige
          {' '}
          <Typography tag="span" strong variant="subtitle1">
            “Continuar o empezar registro”.
          </Typography>
        </Typography>
        <Typography
          variant="subtitle1"
          marginBottom="2x"
        >
          3.
          {' '}
          <Typography tag="span" strong variant="subtitle1">
            Entra con el correo
          </Typography>
          <Typography tag="span" strong variant="subtitle1" color="primary">
            {' '}
            {email}
            {' '}
          </Typography>
          <Typography tag="span" strong variant="subtitle1">
            y el número celular
          </Typography>
          {' '}
          que ya registraste.
        </Typography>
      </Box>

      {/* APPSTORE BUTTONS */}
      <StyledAppButtonsContainer
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginBottom="6x"
        marginTop="12x"
      >
        <a
          target="__blank"
          href="https://link.nubank.com.br/5YMt1FwBMBb"
          onClick={() => downloadAppBtnLP('ios', 'approvedScreen')}
        >
          <AppStoreBadge platform="ios" variant="light" />
        </a>
        <a
          target="__blank"
          href="https://play.google.com/store/apps/details?id=com.nu.production&listing=ric"
          onClick={() => downloadAppBtnLP('android', 'approvedScreen')}
        >
          <AppStoreBadge platform="android" variant="light" />
        </a>
      </StyledAppButtonsContainer>

      {/* CARDS ROWS */}
      <Box
        marginBottom={{
          xs: '1x',
          md: '1x',
          lg: '3.25rem',
        }}
        paddingHorizontal={{
          xs: '0',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          marginBottom="6x"
        >
          {/* CREDIT CARD + CUENTA ROW */}
          <Box
            paddingVertical="4x"
            display="flex"
            paddingHorizontal="2x"
            justifyContent="flex-start"
            borderRadius="12px"
            background="#F6ECFF"
            marginBottom="4x"
          >
            <Image
              alt="Icono de una tarjeta"
              src="one-step/rtr/credit-phase-5.svg"
              webp={false}
              width="120px"
            />
            <Box
              paddingRight="4x"
              width="100%"
            >
              <Typography
                variant="paragraph1"
                whiteSpace="pre-line"
                marginLeft="2x"
                marginBottom="2x"
                strong
              >
                Tarjeta de crédito
              </Typography>
              <Typography
                variant="paragraph1"
                whiteSpace="pre-line"
                strong
                marginLeft="2x"
                color="#820AD1"
              >
                {'$0 anualidad, MSI y \n opciones de pago flexibles.'}
              </Typography>
            </Box>
          </Box>

          <Box marginBottom="4x">
            <Link
              href="https://cdn.nubank.com.br/MX/folleto-informativo.pdf"
              variant="action"
              typographyProps={{ variant: 'paragraph1' }}
              target="_blank"
            >
              CAT de Tarjeta de Crédito Nu
            </Link>
          </Box>

          {/* CUENTA ONLY ROW */}
          <Box
            paddingVertical="4x"
            display="flex"
            paddingHorizontal="2x"
            justifyContent="flex-start"
            borderRadius="12px"
            background="#F3EDFF"
            marginBottom="4x"
          >
            <Image
              alt="Icono de una tarjeta"
              src="one-step/rtr/debit-phase-5.svg"
              webp={false}
              width="120px"
            />
            <Box
              paddingRight="4x"
              width="100%"
            >
              <Typography
                variant="paragraph1"
                whiteSpace="pre-line"
                marginLeft="2x"
                marginBottom="2x"
                strong
              >
                {'Débito y ahorros con \n Cuenta Nu'}
              </Typography>

              <Typography
                variant="paragraph1"
                whiteSpace="pre-line"
                strong
                marginLeft="2x"
                color="#673DBA"
              >
                Débito y puedes crecer tu dinero
                {' '}
                {dynamicYieldValues.dynamicYield}
                % al año.
              </Typography>
            </Box>
          </Box>

          <Box marginBottom="4x">
            <Link
              href="/gat"
              variant="action"
              typographyProps={{ variant: 'paragraph1' }}
              target="_blank"
            >
              GAT Nominal y Real de Cuenta Nu
            </Link>
          </Box>
        </Box>
      </Box>
    </FormStepContainer>
  );
}

MultiproductVariantA.propTypes = {
  email: PropTypes.string,
};

MultiproductVariantA.defaultProps = {
  email: 'tu email',
};

export default MultiproductVariantA;
