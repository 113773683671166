import React from 'react';

import { useRegistrationFormContext } from '../../RegistrationForm/RegistrationForm';

import AutofillFeedbackPersonalInfo from './Shared/AutofillFeedbackPersonalInfo';
import ConfirmCURPInformation from './ConfirmCURPInformation';

function CurpAutofillDecision() {
  const { curpValidationResponse, noValidation } = useRegistrationFormContext();

  return (
    !noValidation && curpValidationResponse && curpValidationResponse.valid
    && curpValidationResponse.birthdate && curpValidationResponse.citizen_id
    && curpValidationResponse.first_surname && curpValidationResponse.gender
    && curpValidationResponse.names && curpValidationResponse.nationality
    && curpValidationResponse.state_of_birth && curpValidationResponse.tax_id ? (
      <ConfirmCURPInformation />
      ) : (
        <AutofillFeedbackPersonalInfo />
      )
  );
}

export default CurpAutofillDecision;
