import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

const StyledDrawer = styled(Drawer)`
  #personal-info-drawer {
    bottom: 0;
    top: auto;
    height: auto;
    border-radius: 16px 16px 0 0;
    padding: 15px;
  }

  a:focus {
    outline: none;
  }
`;

const StyledTitle = styled(Typography)`
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 1.8rem;
`;

const StyledTypography = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 40px;
  
  span {
    font-weight: bold;
  }
`;

function TaxIdDrawer({
  close, isOpen,
}) {
  const openSATSite = () => {
    window.open('https://www.sat.gob.mx/aplicacion/31274/consulta-tu-clave-de-rfc-mediante-curp', '_blank');
    // TBD - Update event
  };

  return (
    <StyledDrawer
      width="100%"
      id="personal-info-drawer"
      direction="bottom"
      desktopDirection="bottom"
      open={isOpen}
      onClose={close}
    >
      {({ DrawerContent, DrawerCloseButton }) => (
        <DrawerContent>
          <DrawerCloseButton onClick={() => close()} />

          <Box position="relative" paddingHorizontal="4x" marginTop="4px">
            <StyledTitle variant="heading3">
              No pasa nada, nosotros te ayudamos 😌
            </StyledTitle>
            {/* TBD - Change the link to RFC */}
            <Typography
              marginTop={{ xs: '4x' }}
              variant="subtitle1"
              color="primary.default"
              strong
              onClick={() => openSATSite()}
              textDecoration="underline"
            >
              Consulta aquí tu RFC
            </Typography>

            <StyledTypography>
              Si aún no lo tienes ¡no te preocupes!
              <span> nosotros lo llenamos por ti con tu CURP correcto. </span>
            </StyledTypography>

          </Box>
        </DrawerContent>
      )}
    </StyledDrawer>
  );
}

TaxIdDrawer.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default TaxIdDrawer;
