import { sanitizeAndValidateRegex, hasTwoOrMoreCharsOnFirstWord, dobYearValidation } from '@nubank/www-latam-commons/utils/form/validationUtils';

import states from './states';
import curpStates from './curpStates';

const RFC_VALID_STRUCTURE_REGEX = /^[a-zñA-Z&Ñ]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[0-9a-zA-Z]{3}$/;
const CURP_VALID_STRUCTURE_REGEX = /^([a-zA-Z][aeiouxAEIOUX][a-zA-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[hmHM][a-zA-Z]{2}[b-df-hj-np-tv-zB-DF-HJ-NP-TV-Z]{3}[a-zA-Z\d])(\d)$/;
const VALID_TWO_DIGITS_DAY = /^(0[1-9]|[1-9]|[12][0-9]|3[01])$/;
const VALID_TWO_DIGITS_MONTH = /^(0[1-9]|[1-9]|1[0-2])$/;
const VALID_FOUR_DIGIT_YEAR = /\d{4}$/;
const VALID_GENDER = /^[hmHM]$/;
const HAS_10_DIGITS = /^\d{10}$/;
const NOT_A_DIGIT = /\D/g;
const SANITIZED_MX_STRING_REGEX = /[^((0-9)|(a-zA-Z)|(\u00C0-\u00FF)|.\-,#|\s)]/;

export const validateCurpStates = value => curpStates.some(state => state.value === value);

export const validateGender = value => sanitizeAndValidateRegex(value, VALID_GENDER);

export function isValidPersonRFC(str) {
  if (!str) { return false; }

  return sanitizeAndValidateRegex(str, RFC_VALID_STRUCTURE_REGEX);
}

const curpBlacklist = [
  'BACA', 'BAKA', 'BUEI', 'BUEY', 'CACA', 'CACO', 'CAGA', 'CAGO', 'CAKA', 'CAKO', 'COGE', 'COGI', 'COJA', 'COJE',
  'COJI', 'COJO', 'COLA', 'CULO', 'FALO', 'FETO', 'GETA', 'GUEI', 'GUEY', 'JOTO', 'KACA', 'KACO', 'KAGA', 'KAGO',
  'KAKA', 'KAKO', 'KOGE', 'KOGI', 'KOJA', 'KOJE', 'KOJI', 'KOJO', 'KOLA', 'KULO', 'LILO', 'LOCA', 'LOCO', 'LOKA',
  'LOKO', 'MAME', 'MAMO', 'MEAR', 'MEAS', 'MEON', 'MIAR', 'MION', 'MOCO', 'MOKO', 'MULA', 'MULO', 'NACA', 'NACO',
  'PEDA', 'PEDO', 'PENE', 'PIPI', 'PITO', 'POPO', 'PUTA', 'PUTO', 'QULO', 'RATA', 'ROBA', 'ROBE', 'ROBO', 'RUIN',
  'SENO', 'TETA', 'VACA', 'VAGA', 'VAGO', 'VAKA', 'VUEI', 'VUEY', 'WUEI', 'WUEY',
];

const isCURPInTheBlackList = str => curpBlacklist.includes(str.toUpperCase().substring(0, 4));
const isValidState = str => curpStates.some(el => el.value === str.toUpperCase().substring(11, 13));

export function isValidPersonCURP(str) {
  if (!str) { return false; }
  if (isCURPInTheBlackList(str)) { return false; }
  if (!isValidState(str)) { return false; }

  return sanitizeAndValidateRegex(str, CURP_VALID_STRUCTURE_REGEX);
}

export function validateFieldsforRFC(values) {
  return hasTwoOrMoreCharsOnFirstWord(values.names)
  && hasTwoOrMoreCharsOnFirstWord(values.firstSurname)
  && dobYearValidation(values.dob);
}

export function validateFieldsForCURP(values) {
  return hasTwoOrMoreCharsOnFirstWord(values.names)
    && hasTwoOrMoreCharsOnFirstWord(values.firstSurname)
    && dobYearValidation(values.dob)
    && validateGender(values.gender)
    && validateCurpStates(values.entity);
}

export function validateMonth(str) {
  return sanitizeAndValidateRegex(str, VALID_TWO_DIGITS_MONTH);
}

export function validateYear(str) {
  return sanitizeAndValidateRegex(str, VALID_FOUR_DIGIT_YEAR);
}

export function validatePhone(str) {
  const numberWithoutSpace = str.replace(NOT_A_DIGIT, '');
  return sanitizeAndValidateRegex(numberWithoutSpace, HAS_10_DIGITS);
}

export function validateDate(year, month, day) {
  if (!(year && month && day)) {
    return false;
  }

  const numericYear = Number(year);
  const numericMonth = Number(month) - 1;
  const numericDay = Number(day);
  const dateToValidate = new Date(numericYear, numericMonth, numericDay);

  return (
    dateToValidate.getFullYear() === numericYear
    && dateToValidate.getMonth() === numericMonth
    && dateToValidate.getDate() === numericDay
  );
}

export function validateDay(str, params, {
  year, month, day,
}) {
  const allFieldsFilled = year ?? month ?? day;
  const allFieldsValid = validateMonth(month) && validateYear(year);
  const isAValidNumber = sanitizeAndValidateRegex(day, VALID_TWO_DIGITS_DAY);

  const shouldValidateCurrentDay = isAValidNumber && allFieldsFilled && allFieldsValid;
  if (!shouldValidateCurrentDay) {
    return isAValidNumber;
  }
  return validateDate(year, month, day);
}

export const validateMXStates = value => states.some(state => state.value === value);

export const hasOneOrMoreSanitizedChars = str => !SANITIZED_MX_STRING_REGEX.test(str);

export const validateAdrressIntNum = str => {
  if (!str) {
    return true;
  }
  return hasOneOrMoreSanitizedChars(str);
};
