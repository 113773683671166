import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Form from '@nubank/nuds-web/components/Form/Form';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Box from '@nubank/nuds-web/components/Box/Box';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Link from '@nubank/nuds-web/components/Link/Link';

import {
  Counter, ErrorMessage, FeedbackBox, StyledTextFieldBox, LoaderBox,
} from '../../components/StyleFeedback';
import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { useRegistrationFormContext } from '../../../RegistrationForm/RegistrationForm';
import Spinner from '../../../../../../components/Spinner/Spinner';
import { isValidPersonCURP } from '../../../../../../utils/form/validationUtils';
import { validateCURP } from '../../../../../../domains/candidate/candidateValidation';
import { curpAutofillEvent } from '../../../../tracking';

const StyledTitle = styled(Typography)`
  font-size: 2rem;

  @media (width >= 768px) {
    font-size: 2.5rem;
  }
`;

const StyledSubtitle = styled(Typography)`
  span {
    font-weight: bold;
  }
`;
const StyledSection = styled(Box)`
  button {
    min-width: 64px;
    height: 64px;
  }

  span {
    color: ${props => (props.loadingBtn ? '#9b999c' : 'auto')};
    margin-bottom: 4px;
  }

  span > svg {
    margin-bottom: -6px;
    margin-left: 15px;
  }
`;

const StyledButton = styled(Button)`
  min-width: 64px;
  height: 64px;
`;

const StyledDrawer = styled(Drawer)`
  #autofill-drawer {
    bottom: 0;
    top: auto;
    height: auto;
    border-radius: 16px 16px 0 0;
    padding: 15px;
    min-height: 55%;
  }

  a:focus {
    outline: none;
  }
`;

function AutofillDrawer() {
  const { discoveryUrlsList } = useSiteContext();
  const {
    setCurpValidationResponse, setNoValidation, setSkipCurpValidation,
    validatedCurps, getValidatedCurps,
  } = useRegistrationFormContext();
  const curpInputRef = useRef();
  const [curpInput, setCurpInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [invalidMessageCURP, setInvalidMessageCURP] = useState(false);
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [disableCURPBtn, setDisableCURPBtn] = useState(true);
  const [firstTimeValidation, setFirstTimeValidation] = useState(true);
  const [fillPersonalInfo, setFillPersonalInfo] = useState(false);

  // CURP Input Validation
  const changeCurpInput = text => {
    if (isValidPersonCURP(text)) {
      setDisableCURPBtn(false);
      setInvalidFormat(false);
    } else {
      setDisableCURPBtn(true);
      setInvalidFormat(true);
    }
  };

  // Trigger the validation of the CURP each time the user update the input
  useEffect(() => {
    changeCurpInput(curpInput);
  }, [curpInput]);

  // Check if the CURP is valid
  const responseValidation = (nextStep, apiResponse) => {
    setCurpValidationResponse(apiResponse);

    if (apiResponse && apiResponse.valid) {
      curpAutofillEvent('drw curp valid');
      nextStep();
    } else {
      // Invalid CURP
      curpAutofillEvent('drw curp invalid');
      setInvalidMessageCURP(true);
      setIsLoading(false);
    }
  };

  const invalidOrTimedOut = nextStep => {
    curpAutofillEvent('drw timeout');
    setCurpValidationResponse({});
    setSkipCurpValidation(true);
    nextStep();
  };

  // Call to validate the CURP
  let apiResponse = false;
  async function curpValidationCall(curp) {
    apiResponse = false;
    const response = await validateCURP(curp, discoveryUrlsList);
    apiResponse = response;
    return apiResponse;
  }

  // Timer to wait only 5s for the response from RENAPO
  const timer = (nextStep, curp) => {
    setFirstTimeValidation(false);
    curpValidationCall(curp);
    let count = 0;
    const timeInterval = setInterval(() => {
      count += 0.5;

      // We have a response on time
      if (count < 5 && apiResponse) {
        responseValidation(nextStep, apiResponse);
        clearInterval(timeInterval);
      }

      // Timeout
      if (count >= 5) {
        invalidOrTimedOut(nextStep);
        clearInterval(timeInterval);
      }
    }, 500);
    timeInterval();
  };

  const handleSubmit = async ({ nextStep }) => {
    setNoValidation(false);

    // User clicks btn to fill info manually
    if (fillPersonalInfo) {
      curpAutofillEvent('drw genera tu curp');
      nextStep();
      setNoValidation(true);
      return;
    }

    setIsLoading(true);
    // Check if the CURP was already validated
    if (!firstTimeValidation && validatedCurps.includes(curpInput)) {
      setSkipCurpValidation(true);
      nextStep();
    } else {
      getValidatedCurps(curpInput);
      timer(nextStep, curpInput);
    }
  };

  useEffect(() => {
    curpInputRef.current?.focus();
    setInvalidFormat(false);
    curpAutofillEvent('drw displayed');
  }, []);

  return (
    <Form.Step
      initialValues={{
        curp: '',
      }}
      initialTouched={{ countryOfBirth: true }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {() => (
        <Box
          overflowY="auto"
          paddingTop={{ xs: '24px', lg: '40px' }}
          paddingBottom="4x"
          paddingHorizontal={{ xs: '24px', lg: '40px' }}
          position="relative"
          display="flex"
          flexDirection="column"
          minHeight="100%"
        >

          {/* Starts background (Not Functional) */}
          <StyledTitle
            variant="heading3"
            marginBottom="4x"
          >
            Completa tus datos personales
          </StyledTitle>
          <StyledSubtitle variant="subtitle1" marginBottom="4x">
            <span>No uses abreviaturas</span>
            {' '}
            y cuida que tus datos
            <span> coincidan con tu identificación oficial.</span>
          </StyledSubtitle>
          <TextField
            name="n"
            label="Nombre(s) escríbelo sin abreviaturas"
          />
          <TextField
            name="firstSurn"
            label="Primer apellido"
          />
          <TextField
            name="secondSurn"
            label="Segundo apellido"
          />
          {/* Ends background (Not Functional) */}

          <StyledDrawer
            width="100%"
            id="autofill-drawer"
            direction="bottom"
            desktopDirection="bottom"
            open
          >
            {({ DrawerContent }) => (
              <DrawerContent>
                <Box position="relative" paddingHorizontal="4x" marginTop="4px">

                  <Typography
                    variant="heading4"
                    marginVertical="4x"
                    strong
                  >
                    Con tu CURP ingresas tus datos personales como si fuera magia
                  </Typography>

                  <StyledSubtitle variant="subtitle1" marginBottom="4x">
                    O si lo prefieres, puedes
                    {' '}
                    <span>escribir tus datos manualmente</span>
                  </StyledSubtitle>

                  {/* CURP Input Start */}
                  <StyledTextFieldBox>
                    { isLoading
                    && (
                    <LoaderBox
                      position="relative"
                      float="right"
                      top="33px"
                    >
                      <Spinner color="#820AD1" />
                    </LoaderBox>
                    )}
                    <TextField
                      id="curp"
                      name="curp"
                      ref={curpInputRef}
                      maxLength={18}
                      label="Escribe tu CURP"
                      onInput={e => setCurpInput(e.target.value)}
                    />
                  </StyledTextFieldBox>
                  <FeedbackBox adjustMargin={isLoading}>
                    <ErrorMessage>
                      {invalidMessageCURP && isValidPersonCURP(curpInput) ? 'Revisa tu CURP o llena tus datos' : null}
                      {invalidFormat ? 'Confirma tu CURP antes de continuar' : null}
                    </ErrorMessage>
                    <Counter input={curpInput.length} length={18}>
                      {`${18 - curpInput.length}/18`}
                    </Counter>
                  </FeedbackBox>
                  {/* CURP Input Ends */}

                  <Box
                    backgroundColor="#F6ECFF"
                    borderRadius="25px"
                    marginVertical="12x"
                    paddingHorizontal={{ xs: '6x' }}
                    paddingVertical={{ xs: '4x' }}
                  >

                    <Typography strong>
                      Encuentra tu CURP en tu INE 🔎
                    </Typography>
                    <Link
                      variant="paragraph1"
                      href="https://www.gob.mx/curp/"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                    >
                      También puedes consultar el sitio oficial
                    </Link>
                  </Box>

                  <StyledSection
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginTop="auto"
                  >
                    <Button
                      id="autofill-submit-btn"
                      variant="contained"
                      styleVariant="primary"
                      extended
                      type="submit"
                      disabled={disableCURPBtn || isLoading}
                    >
                      Continuar con CURP
                      { isLoading ? <Spinner /> : null }
                    </Button>
                  </StyledSection>

                  <StyledButton
                    variant="contained"
                    styleVariant="white"
                    extended
                    type="submit"
                    onClick={() => setFillPersonalInfo(true)}
                    disabled={isLoading}
                  >
                    Escribir datos manualmente
                  </StyledButton>

                </Box>

              </DrawerContent>
            )}
          </StyledDrawer>

        </Box>
      )}
    </Form.Step>
  );
}

export default AutofillDrawer;
