const states = [
  { value: 'AS', label: 'Aguascalientes', entity: 'AG' },
  { value: 'BC', label: 'Baja California', entity: 'BC' },
  { value: 'BS', label: 'Baja California Sur', entity: 'BS' },
  { value: 'CC', label: 'Campeche', entity: 'CM' },
  { value: 'CS', label: 'Chiapas', entity: 'CS' },
  { value: 'CH', label: 'Chihuahua', entity: 'CH' },
  { value: 'CL', label: 'Coahuila', entity: 'CO' },
  { value: 'CM', label: 'Colima', entity: 'CL' },
  { value: 'DF', label: 'Ciudad de México', entity: 'DF' },
  { value: 'DG', label: 'Durango', entity: 'DG' },
  { value: 'GT', label: 'Guanajuato', entity: 'GT' },
  { value: 'GR', label: 'Guerrero', entity: 'GR' },
  { value: 'HG', label: 'Hidalgo', entity: 'HG' },
  { value: 'JC', label: 'Jalisco', entity: 'JA' },
  { value: 'MC', label: 'Estado de México', entity: 'EM' },
  { value: 'MN', label: 'Michoacán', entity: 'MI' },
  { value: 'MS', label: 'Morelos', entity: 'MO' },
  { value: 'NT', label: 'Nayarit', entity: 'NA' },
  { value: 'NL', label: 'Nuevo León', entity: 'NL' },
  { value: 'OC', label: 'Oaxaca', entity: 'OA' },
  { value: 'PL', label: 'Puebla', entity: 'PU' },
  { value: 'QT', label: 'Querétaro', entity: 'QT' },
  { value: 'QR', label: 'Quintana Roo', entity: 'QR' },
  { value: 'SP', label: 'San Luis Potosí', entity: 'SL' },
  { value: 'SL', label: 'Sinaloa', entity: 'SI' },
  { value: 'SR', label: 'Sonora', entity: 'SO' },
  { value: 'TC', label: 'Tabasco', entity: 'TB' },
  { value: 'TS', label: 'Tamaulipas', entity: 'TM' },
  { value: 'TL', label: 'Tlaxcala', entity: 'TL' },
  { value: 'VZ', label: 'Veracruz', entity: 'VE' },
  { value: 'YN', label: 'Yucatán', entity: 'YU' },
  { value: 'ZS', label: 'Zacatecas', entity: 'ZA' },
  { value: 'NE', label: 'Nací en el Extranjero', entity: 'NE' },
];

export default states;
