import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  hasTwoOrMoreCharsOnFirstWord,
  optionalHasTwoOrMoreCharsOnFirstWord,
  hasFiveNumericalChars,
  hasJustAlphaNumericalCharsWithSpacesAndDots,
  hasFourNumericalChars,
} from '@nubank/www-latam-commons/utils/form/validationUtils';

import {
  isValidPersonRFC,
  isValidPersonCURP,
  validateDay,
  validateMonth,
  validateYear,
  validatePhone,
  validateMXStates,
  hasOneOrMoreSanitizedChars,
  validateAdrressIntNum,
} from '../../../../utils/form/validationUtils';
import FormHeader from '../FormHeader/FormHeader';
import { StyledForm } from '../../styles/StyledForm';

const extraValidations = {
  names: hasTwoOrMoreCharsOnFirstWord,
  firstSurname: hasTwoOrMoreCharsOnFirstWord,
  secondSurname: optionalHasTwoOrMoreCharsOnFirstWord,
  day: validateDay,
  month: validateMonth,
  year: validateYear,
  taxId: isValidPersonRFC,
  curp: isValidPersonCURP,
  street: hasOneOrMoreSanitizedChars,
  streetNumberExt: hasOneOrMoreSanitizedChars,
  streetNumberInt: validateAdrressIntNum,
  locality: hasOneOrMoreSanitizedChars,
  postCode: hasFiveNumericalChars,
  city: hasJustAlphaNumericalCharsWithSpacesAndDots,
  addressState: validateMXStates,
  phone: validatePhone,
  nip: hasFourNumericalChars,
};

const initialAddress = {
  streetNumberExt: '',
  street: '',
  locality: '',
  city: '',
  addressState: '',
  postCode: '',
};

const initialProspectData = {
  marketingId: null,
  prospectId: null,
  hasThrottle: false,
  hasRealtimeAnalysis: false,
  realtimeUrl: null,
  prospectType: null,
};

const RegistrationFormContext = React.createContext();
RegistrationFormContext.displayName = 'RegistrationFormContext';

const RegistrationForm = ({ children, selectedAddress: address }) => {
  const [selectedAddress, setSelectedAddress] = useState(address);
  const [prospectRegistrationData, setProspectRegistrationData] = useState(initialProspectData);

  // Used for RENAPO CURP validations PI CURP Feedback xp
  const [uncheckPersonalInfoBox, setUncheckPersonalInfoBox] = useState(false);

  // API response info for the CURP validation (PI CURP Autofill xp)
  const [curpValidationResponse, setCurpValidationResponse] = useState({});
  const [noValidation, setNoValidation] = useState(false);
  const [skipCurpValidation, setSkipCurpValidation] = useState(false);
  // Arr of validated CURPs
  const [validatedCurps, setValidatedCurps] = useState([]);
  const getValidatedCurps = value => {
    const curp = value.toUpperCase();
    if (!validatedCurps.includes(curp)) {
      setValidatedCurps([...validatedCurps, curp]);
    }
  };

  return (
    <RegistrationFormContext.Provider value={{
      selectedAddress,
      setSelectedAddress,
      prospectRegistrationData,
      setProspectRegistrationData,
      uncheckPersonalInfoBox,
      setUncheckPersonalInfoBox,
      curpValidationResponse,
      setCurpValidationResponse,
      noValidation,
      setNoValidation,
      skipCurpValidation,
      setSkipCurpValidation,
      validatedCurps,
      getValidatedCurps,
    }}
    >
      <StyledForm
        renderHeader={props => <FormHeader {...props} />}
        extraValidations={extraValidations}
      >
        {children}
      </StyledForm>
    </RegistrationFormContext.Provider>

  );
};

RegistrationForm.defaultProps = {
  selectedAddress: initialAddress,
};

RegistrationForm.propTypes = {
  children: PropTypes.node.isRequired,
  selectedAddress: PropTypes.shape({
    addressState: PropTypes.string,
    city: PropTypes.string,
    locality: PropTypes.string,
    postCode: PropTypes.string,
    street: PropTypes.string,
    streetNumberExt: PropTypes.string,
  }),
};

function useRegistrationFormContext() {
  const context = React.useContext(RegistrationFormContext);
  if (context === undefined) {
    throw new Error('useRegistrationFormData must be used within a <RegistrationForm />');
  }
  return context;
}

export { RegistrationForm, useRegistrationFormContext };
